import { getSignal } from '@api/index';
import WithUnit from '@components/WithUnit';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { getRatingCSSColor } from '@utils/rating';
import _ from 'lodash';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { Column } from '../../../columns/createColumn';
import { ValidUnit } from '../../../constants/units';
import { HydratedEntity } from '../../../types';
import {
    acceleration,
    accelerationPerform3,
    accelerationPerform3WithOvertake,
    braking,
    coasting,
    coastingPerform3,
    cruisePerform3,
    cruisePerform3v3,
    cruisePerform3WithOvertake,
    electricCruise,
    electricCruisePerform3,
    electricCruisePerform3v3,
    electricCruisePerform3WithOvertake,
    fuelCruise,
    overspeed,
} from '../../foresightedDriving/configuration';
import { DataboxChildren, DataboxTitleContainer, WrappableDataboxContainer } from '../components/Databox';
import ProgressBar from '../components/ProgressBar';
import ForesightedDrivingTitleBar from './ForesightedDrivingTitleBar';
import OperationalConditions from './OperationalConditions';

const styles = StyleSheet.create({
    foresightDrivingTop: {
        flexDirection: 'row',
        borderBottom: '#2a3740',
        borderBottomWidth: 1.2,
        justifyContent: 'center',
        paddingBottom: 15,
        paddingTop: 5,
        borderLeft: '#2a3740',
        borderRight: '#2a3740',
        borderWidth: 1.2,
        borderTopWidth: 0,
    },
    foresightDrivingBottom: {
        flexDirection: 'column',
        width: '100%',
    },
    foresightedDrivingBox: {
        borderColor: '#2a3740',
        borderWidth: 1.2,
        padding: 10,
        marginTop: -1.2,
        flexDirection: 'column',
        borderBottomWidth: 1.2,
    },
    foresightedDrivingBar: {
        height: 15,
        width: 120,
        marginBottom: 11,
        marginTop: 11,
    },
});
type Entry = {
    title: ReactElement;
    values: Column[];
};

type Discipline = {
    id: string;
    title: Column;
    entries: Entry[];
};

export default function ForesightedDriving({
    data,
    settings,
    disciplines,
    isElectric,
}: {
    data: HydratedEntity;
    settings: {
        totalRatingWithCC: boolean;
        isPerform3Enabled: boolean;
        shouldShowOvertakeInCruise?: boolean;
        shouldUseV3Ratings?: boolean;
    };
    disciplines?: Discipline[];
    isElectric: boolean;
}) {
    if (_.isUndefined(disciplines)) {
        // Determine the base disciplines based on the vehicle type
        const cruiseData = isElectric ? electricCruise : fuelCruise;
        const initialDisciplines = [acceleration, braking, cruiseData, overspeed];
        disciplines = [...initialDisciplines] as Discipline[];

        if (settings.shouldUseV3Ratings) {
            if (!isElectric) {
                disciplines.unshift(coastingPerform3 as Discipline);
            }

            const getCruiseDiscipline = () => {
                return isElectric ? electricCruisePerform3v3 : cruisePerform3v3;
            };

            if (isElectric) {
                disciplines.splice(2, 1, getCruiseDiscipline() as Discipline);
                disciplines.splice(0, 2);
            } else {
                disciplines.splice(3, 1, getCruiseDiscipline() as Discipline);
            }
        } else if (settings.isPerform3Enabled) {
            if (!isElectric) {
                disciplines.unshift(coastingPerform3 as Discipline);
            }

            const getCruiseDiscipline = () => {
                return settings.shouldShowOvertakeInCruise
                    ? isElectric
                        ? electricCruisePerform3WithOvertake
                        : cruisePerform3WithOvertake
                    : isElectric
                    ? electricCruisePerform3
                    : cruisePerform3;
            };

            const getAccelerationDiscipline = () => {
                return settings.shouldShowOvertakeInCruise ? accelerationPerform3WithOvertake : accelerationPerform3;
            };

            if (isElectric) {
                disciplines.splice(2, 1, getCruiseDiscipline() as Discipline);
                disciplines.splice(0, 2);
            } else {
                disciplines.splice(1, 1, getAccelerationDiscipline() as Discipline);
                disciplines.splice(3, 1, getCruiseDiscipline() as Discipline);
            }
        } else {
            disciplines.unshift(coasting as Discipline);
        }
    }

    let correctTotalValueSignal;
    if (settings.isPerform3Enabled && !settings.shouldUseV3Ratings) {
        correctTotalValueSignal = 'foresightedDrivingPercentageV2';
    } else {
        correctTotalValueSignal = 'foresightedDrivingPercentage';
    }

    const totalValue = getSignal(data, correctTotalValueSignal, 0) as number;
    const color = getRatingCSSColor(totalValue).hexColor;

    return (
        <WrappableDataboxContainer>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ width: '100%' }}>
                    <View wrap={false}>
                        <DataboxTitleContainer>
                            <FormattedMessage id={'sidepanel.foresightedDrivingRatings'} />
                        </DataboxTitleContainer>
                        <View style={styles.foresightDrivingTop} wrap={false}>
                            <OperationalConditions data={data} />
                            <View style={{ justifyContent: 'center' }}>
                                <View style={{ justifyContent: 'center', flexDirection: 'row', fontSize: 12 }}>
                                    <FormattedMessage id={'details.rating'} />
                                </View>
                                <View style={styles.foresightedDrivingBar} data-test="foresightedDrivingBar">
                                    <ProgressBar color={color} progress={totalValue} />
                                </View>
                                <View
                                    style={{
                                        justifyContent: 'center',
                                        flexDirection: 'row',
                                        fontSize: 12,
                                        fontFamily: 'Bold',
                                        color,
                                    }}
                                >
                                    <Text>
                                        <WithUnit unit={ValidUnit.PERCENTAGE}>
                                            <FormattedNumber value={totalValue} maximumFractionDigits={0} />
                                        </WithUnit>
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <DataboxChildren>
                        <View style={styles.foresightDrivingBottom} data-test="items">
                            {disciplines.map(({ title, entries }, index) => (
                                <Item key={index} title={title} entries={entries} data={data} />
                            ))}
                        </View>
                    </DataboxChildren>
                </View>
            </View>
        </WrappableDataboxContainer>
    );
}

const Row = ({ title, columns, data }: { title: ReactElement; columns: Column[]; data: HydratedEntity }) => {
    // 33% was chosen for now because the max columns is 3. This is needed
    // in order to emulate aligned rows and columns. Real tables aren't yet available.
    const columnWidthInPercentage = '33%';
    const titleSection = (
        <View data-test="title" style={{ width: columnWidthInPercentage }}>
            {title}
        </View>
    );
    return (
        <View style={{ flexDirection: 'row', marginBottom: 5 }}>
            {titleSection}
            {columns.map(column => (
                <Text data-test="value" key={column.key} style={{ width: columnWidthInPercentage }}>
                    {column.formatter(column.valueExtractor(data))}
                </Text>
            ))}
        </View>
    );
};

export const ForesightedDrivingContent = ({ children }: { children: ReactNode }) => (
    <View style={{ flexDirection: 'column', marginLeft: 20, marginTop: 5 }}>{children}</View>
);

const Item = ({ title, entries, data }: { title: Column; entries: Entry[]; data: HydratedEntity }) => {
    const value = title.valueExtractor(data) as number | null | undefined;
    return (
        <View style={styles.foresightedDrivingBox} wrap={false}>
            <ForesightedDrivingTitleBar title={title} value={value} />
            <ForesightedDrivingContent>
                {entries.map(({ title, values: columns }, index) => (
                    <Row data-test="row" key={index} title={title} columns={columns} data={data} />
                ))}
            </ForesightedDrivingContent>
        </View>
    );
};
