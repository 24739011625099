export const ELECTRIC_KPIS_ATTRIBUTES = [
    'electric_average_idling_consumption',
    'electric_average_driving_consumption',
    'electric_operating_consumption',
    'electric_average_operating_consumption',
    'electric_average_operating_consumption_range',
    'electric_recuperation',
    'electric_efficiency',
    'electric_recuperation_per_km',
    'electric_recuperation_km_per_kwh',
    'electric_recuperation_per_hour',
];

export const DRIVER_REQUEST_BY_TIME_COMMON_ATTRIBUTES = ['driver_ids', 'vehicle_ids', 'result_from', 'result_to'];

export const DRIVER_REQUEST_BY_TIME_ATTRIBUTES = [
    ...DRIVER_REQUEST_BY_TIME_COMMON_ATTRIBUTES,
    'foresighted_driving_percentage',
    'foresighted_driving_percentage_without_cc',
    'coasting_rating',
    'acceleration_pedal_rating',
    'braking_rating',
    'cruise_control_rating',
    'overspeed_rating',
    'interval_desc', // TODO remove this when cache service is no longer used
    'operating_fuel_consumption',
    'fuel_consumption',
    'distance_fuel_consumption',
    'driving_consumption',
    'idling_consumption',
    'operating_time',
    'driving_time',
    'mileage',
    'average_weight',
    'average_speed',
    'fuel_efficiency',
    'total_power_consumption',
];

export const ELECTRIC_DRIVER_REQUEST_BY_TIME_ATTRIBUTES = [
    ...DRIVER_REQUEST_BY_TIME_COMMON_ATTRIBUTES,
    ...ELECTRIC_KPIS_ATTRIBUTES,
];

export const HARSH_RATINGS_ATTRIBUTES = ['harsh_acceleration_rating', 'harsh_braking_rating'];

export const EXCESSIVE_IDLING_RATINGS_ATTRIBUTES = ['excessive_idling_rating'];

export const DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_OFF = [
    'cruise_control_rating_v2',
    'acceleration_pedal_rating_v2',
    'foresighted_driving_percentage_v2',
];

export const DRIVER_REQUEST_ATTRIBUTES_OVERTAKE_ON = [
    'cruise_control_rating_v3',
    'acceleration_pedal_rating',
    'foresighted_driving_percentage_v3',
];

export const DRIVER_REQUEST_ATTRIBUTES_V1_RATINGS = [
    'coasting_distance',
    'coasting_time',
    'passive_coasting_time',
    'passive_coasting_distance',
    'passive_coasting_count',
    'operating_days',
    'total_number_of_days',
];

export const DRIVER_REQUEST_ATTRIBUTES_V2_RATINGS = [
    ...DRIVER_REQUEST_ATTRIBUTES_V1_RATINGS,
    'total_number_of_days',
    'foresighted_driving_percentage_without_cc_v2',
];

export const DRIVER_REQUEST_ATTRIBUTES = [
    'driver_ids',
    'vehicle_ids',
    'result_from',
    'result_to',
    'foresighted_driving_percentage',
    'foresighted_driving_percentage_without_cc',
    'coasting_rating',
    'active_coasting_distance',
    'active_coasting_time',
    'active_coasting_count',
    'acceleration_pedal_rating',
    'kickdown_distance',
    'kickdown_time',
    'kickdown_count',
    'acceleration_pedal_distance_with_cc',
    'acceleration_pedal_time_with_cc',
    'acceleration_pedal_count_with_cc',
    'braking_rating',
    'service_brake_distance',
    'service_brake_time',
    'service_brake_count',
    'motor_brake_distance',
    'motor_brake_time',
    'motor_brake_count',
    'retarder_distance',
    'retarder_time',
    'retarder_count',
    'emergency_brake_count',
    'cruise_control_rating',
    'cruise_control_distance_in_potential',
    'cruise_control_time_in_potential',
    'no_cruise_control_distance_in_potential',
    'no_cruise_control_time_in_potential',
    'cruise_control_potential_distance',
    'cruise_control_consumption_in_potential',
    'no_cruise_control_consumption_in_potential',
    'overspeed_rating',
    'overspeed_distance',

    'inaccurate_vehicle_ids',

    'idling_time',
    'co_2_emission',
    'operating_fuel_consumption',
    'fuel_consumption',
    'distance_fuel_consumption',
    'driving_consumption',
    'idling_consumption',
    'operating_time',
    'driving_time',
    'mileage',
    'average_weight',
    'average_speed',
    'fuel_efficiency',
    'interval_desc', // TODO remove this when cache service is no longer used
    'total_power_consumption',
];
