import { getSignal } from '@api/index';
import Rating from '@features/opcon/components/Rating';
import { columns, getColumn } from '@utils/columns';

import createColumn, { Column } from '../../columns/createColumn';
import { coloredRatingBar, coloredTotalRating } from '../../columns/DOM';
import { HydratedEntity, HydratedEntityWithChildren, OpconSignals, Signal, Vehicle } from '../../types';
import ClickableVehicleList from './ClickableVehicleList';
import TableVehicleLabel from './VehicleLabel';

export const vehiclesNamesTableEntry = (
    vehicles: Vehicle[],
    data: HydratedEntityWithChildren | HydratedEntity | undefined
) => <TableVehicleLabel vehicles={vehicles} data={data} />;

export const replacements: { [key: string]: Record<string, unknown> } = {
    vehicles: { formatter: vehiclesNamesTableEntry },
    coastingRating: { formatter: coloredRatingBar },
    accelerationPedalRating: { formatter: coloredRatingBar },
    brakingRating: { formatter: coloredRatingBar },
    cruiseControlRating: { formatter: coloredRatingBar },
    overspeedRating: { formatter: coloredRatingBar },
    totalRating: { formatter: coloredTotalRating },
    totalRatingWithoutCC: { formatter: coloredTotalRating },
    operationCondition: {
        formatter: function operatingConditionRating(_signal: Signal<number>, row: HydratedEntity) {
            return (
                <div className="max-width-100 height-20">
                    <Rating
                        opCon={getSignal(row, 'operatingConditionResponse', {}) as OpconSignals}
                        totalMileage={getSignal(row, 'mileage', 0)}
                    />
                </div>
            );
        },
    },
    accelerationPedalRatingV2: { formatter: coloredRatingBar },
    cruiseControlRatingV2: { formatter: coloredRatingBar },
    cruiseControlRatingV3: { formatter: coloredRatingBar },
    totalRatingV2: { formatter: coloredTotalRating },
    totalRatingV3: { formatter: coloredTotalRating },
    harshAccelerationRating: { formatter: coloredRatingBar },
    harshBrakingRating: { formatter: coloredRatingBar },
    excessiveIdlingRating: { formatter: coloredRatingBar },
};

export const tableColumns = columns.map(column => {
    const replacementColumnProps = replacements[column.key];
    return replacementColumnProps ? createColumn({ ...column, ...replacementColumnProps }) : column;
});

// eslint-disable-next-line react/display-name
export const vehiclesNamesList = (onVehiclesNameClick: (vehicle: unknown) => void) => (
    vehicles: Vehicle[],
    data: { children?: { id: string; vehicles: Vehicle[] }[] }
) => <ClickableVehicleList onClick={onVehiclesNameClick} rows={data.children ? data.children : []} />;

export const getTopColumns = (isVehiclesNameClickable: boolean, onVehiclesNameClick: (vehicle: unknown) => void) => {
    const defaultVehicleNamesColumn = getColumn('vehicleNames') as Column;
    const vehiclesColumn = isVehiclesNameClickable
        ? createColumn({
              ...defaultVehicleNamesColumn,
              formatter: vehiclesNamesList(onVehiclesNameClick),
          })
        : defaultVehicleNamesColumn;
    return [getColumn('explicitDrivers'), vehiclesColumn];
};
