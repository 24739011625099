import { getSignal } from '@api/index';
import _ from 'lodash';

import {
    cruiseControlConsumptionInPotential,
    distanceFuelConsumption,
    drivingConsumption,
    electricAverageDrivingConsumption,
    electricAverageIdlingConsumption,
    electricAverageOperatingConsumption,
    electricAverageOperatingConsumptionRange,
    electricEfficiency,
    electricOperatingConsumption,
    electricRecuperation,
    fuelConsumption,
    fuelEfficiency,
    idlingConsumption,
    noCruiseControlConsumptionInPotential,
    operatingFuelConsumption,
} from '../../columns/consumption';
import createColumn, { Column } from '../../columns/createColumn';
import {
    accelerationPedalDistanceWithCc,
    activeCoastingDistance,
    coastingCcDistance,
    coastingDistance,
    cruiseControlDistanceInPotential,
    cruiseControlPotentialDistance,
    kickdownDistance,
    mileage,
    motorBrakeDistance,
    noCruiseControlDistanceInPotential,
    overspeedDistance,
    passiveCoastingDistance,
    retarderDistance,
    serviceBrakeDistance,
} from '../../columns/distance';
import { drivenElevationDown, drivenElevationUp } from '../../columns/height';
import {
    accelerationPedalRating,
    accelerationPedalRatingV2,
    averageSlopeDown,
    averageSlopeUp,
    brakingRating,
    coastingRating,
    cruiseControlRating,
    cruiseControlRatingV2,
    cruiseControlRatingV3,
    overspeedRating,
    totalRating,
    totalRatingV2,
    totalRatingV3,
    totalRatingWithoutCC,
    totalRatingWithoutCCV2,
} from '../../columns/percentage';
import { scoreSignal } from '../../columns/score';
import { averageSpeed } from '../../columns/speed';
import {
    accelerationPedalTimeWithCc,
    activeCoastingTime,
    coastingCCTime,
    coastingTime,
    cruiseControlTimeInPotential,
    drivingTime,
    idlingTime,
    kickdownTime,
    motorBrakeTime,
    noCruiseControlTimeInPotential,
    operatingTime,
    passiveCoastingTime,
    retarderTime,
    serviceBrakeTime,
} from '../../columns/time';
import { averageWeight, co2Emission } from '../../columns/weight';
import {
    Driver,
    HydratedEntity,
    HydratedEntityWithOpcon,
    HydratedEntityWithShouldShowFuelType,
    Vehicle,
} from '../../types';
import { isValidScore } from '../opcon/isValidScore';
import brakeImage from './assets/brake.png';
import coastingImage from './assets/coasting.png';
import cruiseControlImage from './assets/cruise-control.png';
import kickDownImage from './assets/kickdown.png';
import speedImage from './assets/speed.png';
import DriverLabel from './components/DriverLabel';
import FromToRange from './components/FromToRange';
import SimpleBarChart from './components/SimpleBarChart';
import Systems from './components/Systems';
import VehicleLabel from './components/VehicleLabel';

const fromToRangeFormatter = (_: Date, entity: HydratedEntity) => {
    const start = getSignal<Date | string>(entity, 'start', '-');
    const end = getSignal<Date | string>(entity, 'end', '-');
    return <FromToRange start={start} end={end} />;
};

const opConFormatter = (score: number, entity: HydratedEntityWithOpcon) => {
    const adjustedScore = isValidScore(entity.signals, getSignal(entity, 'mileage', 0)) ? score : -1;
    return <SimpleBarChart value={adjustedScore} />;
};

const systemsFormatter = (vehicles: Vehicle[]) => {
    return <Systems vehicles={vehicles} />;
};

const vehicleFormatter = (vehicles: Vehicle[], entity: HydratedEntityWithShouldShowFuelType) => (
    <VehicleLabel vehicles={vehicles} shouldShowFuelType={entity.shouldShowFuelType} />
);
const driverFormatter = (drivers: Driver[]) => <DriverLabel drivers={drivers} />;

const ICONS: Record<string, string> = Object.freeze({
    coasting: coastingImage,
    brake: brakeImage,
    speed: speedImage,
    kickdown: kickDownImage,
    'cruise-control': cruiseControlImage,
});

const tripStartEnd = createColumn({
    key: 'tripStartEnd',
    dataField: 'start',
    labelId: 'tripStartEnd',
    formatter: fromToRangeFormatter,
    dataSort: true,
});

const vehicle = createColumn({
    key: 'vehicle',
    dataField: 'vehicles',
    labelId: 'vehicle',
    formatter: vehicleFormatter,
    dataSort: true,
});

const drivers = createColumn({
    key: 'drivers',
    dataField: 'drivers',
    labelId: 'driver',
    formatter: driverFormatter,
    dataSort: true,
});

const operationCondition = createColumn({
    key: 'operationCondition',
    dataField: 'operationCondition',
    labelId: 'operationCondition',
    formatter: opConFormatter,
    unit: scoreSignal.unit,
    dataSort: true,
});

const system = createColumn({
    key: 'system',
    dataField: 'vehicles',
    labelId: 'vehicle.system',
    formatter: systemsFormatter,
    dataSort: true,
});

const columns: Column[] = [
    drivers,
    vehicle,
    system,
    tripStartEnd,

    distanceFuelConsumption,
    co2Emission,
    idlingConsumption,
    fuelConsumption,
    averageWeight,
    averageSpeed,
    drivenElevationUp,
    drivenElevationDown,

    drivingTime,
    idlingTime,
    operatingTime,
    activeCoastingTime,
    passiveCoastingTime,
    coastingCCTime,
    coastingTime,
    kickdownTime,
    accelerationPedalTimeWithCc,
    serviceBrakeTime,
    motorBrakeTime,
    retarderTime,
    cruiseControlTimeInPotential,
    noCruiseControlTimeInPotential,

    averageSlopeDown,
    averageSlopeUp,
    coastingRating,
    accelerationPedalRating,
    accelerationPedalRatingV2,
    brakingRating,
    cruiseControlRating,
    cruiseControlRatingV2,
    cruiseControlRatingV3,
    overspeedRating,
    totalRating,
    totalRatingWithoutCC,
    totalRatingV2,
    totalRatingV3,
    totalRatingWithoutCCV2,

    mileage,
    coastingCcDistance,
    coastingDistance,
    kickdownDistance,
    accelerationPedalDistanceWithCc,
    serviceBrakeDistance,
    motorBrakeDistance,
    cruiseControlDistanceInPotential,
    noCruiseControlDistanceInPotential,
    cruiseControlPotentialDistance,
    overspeedDistance,
    activeCoastingDistance,
    passiveCoastingDistance,
    retarderDistance,

    noCruiseControlConsumptionInPotential,
    cruiseControlConsumptionInPotential,
    operatingFuelConsumption,
    drivingConsumption,
    operationCondition,
    fuelEfficiency,

    electricEfficiency,
    electricAverageDrivingConsumption,
    electricAverageIdlingConsumption,
    electricAverageOperatingConsumption,
    electricOperatingConsumption,
    electricAverageOperatingConsumptionRange,
    electricRecuperation,
];

const mapIconsFromClassToImage = (column: Column) => {
    const possibleIconMatch = column.icon && column.icon.match(/rioglyph-(?<icon>([\w-])*)$/);
    const iconName = _.get(possibleIconMatch, 'groups.icon');
    const icon = iconName ? ICONS[iconName] : undefined;
    return { ...column, icon };
};

export const getColumn = (requestedColumn: string, existingColumns = columns) =>
    existingColumns.find(e => e.key === requestedColumn);

export const getColumns = (requestedColumns: string[], existingColumns = columns) =>
    requestedColumns
        .map(r => existingColumns.find(e => e.key === r))
        .filter((c: Column | undefined): c is Column => Boolean(c))
        .map(mapIconsFromClassToImage);
