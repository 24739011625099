import { getSignal, VehicleFuelType } from '@api/index';
import driverImage from '@features/pdf/assets/driver_white.png';
import truckDieselImage from '@features/pdf/assets/truck_diesel_white.png';
import truckElectricImage from '@features/pdf/assets/truck_electric_white.png';
import { CalculationParams } from '@features/settings/reducer';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import { extractDriverName, extractVehicleName } from '@utils/stringFormatters';
import { clone } from 'lodash';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import { Column } from '../../../columns/createColumn';
import { DateRange, HydratedEntity } from '../../../types';
import Logo from '../assets/man-logo.png';
import CalculationParameters from '../components/CalculationParameters';
import Databox, { DataboxTitleContainer, WrappableDataboxContainer } from '../components/Databox';
import DateRangeSection from '../components/DateRangeSection';
import DriverLabel from '../components/DriverLabel';
import Footer from '../components/Footer';
import VehicleLabel from '../components/VehicleLabel';
import ForesightedDriving from './ForesightedDriving';
import GeneralDataPdf from './GeneralDataPdf';
import OperationalRange from './OperationalRange';
import styles from './styles';
import TopologyDataPdf from './TopologyData';
import TrafficData from './TrafficData';

type SettingsForPdfGenerationTypes = {
    totalRatingWithCC: boolean;
    isDriver: boolean;
    shouldShowOvertakeInCruise?: boolean;
    calculationParameters: CalculationParams;
    isPerform3Enabled: boolean;
    shouldShowFuelType: boolean;
    isTruEEnabled?: boolean;
    shouldUseV3Ratings?: boolean;
};

type ContentProps = {
    data: HydratedEntity;
    columns: { electricColumns: Column[]; dieselColumns: Column[] };
    dateRange: DateRange;
    settings: SettingsForPdfGenerationTypes;
};

const getTitleContent = (settings: SettingsForPdfGenerationTypes, data: HydratedEntity) => {
    return (
        <>
            <View style={styles.title}>
                {settings.isDriver ? (
                    <FormattedMessage id="sidebarPdfTitle" values={{ asset: extractDriverName(data.drivers[0]) }} />
                ) : (
                    <FormattedMessage id={'sidebarPdfTitle'} values={{ asset: extractVehicleName(data.vehicles[0]) }} />
                )}
            </View>
        </>
    );
};

const getDateRangeContent = (data: HydratedEntity, startDate: Date, endDate: Date) => {
    return (
        <>
            <DateRangeSection title={<FormattedMessage id="queryPeriod" />} start={startDate} end={endDate} />
            <DateRangeSection
                title={<FormattedMessage id="tripStartEnd" />}
                start={getSignal<Date | undefined>(data, 'start', undefined)}
                end={getSignal<Date | undefined>(data, 'end', undefined)}
            />
        </>
    );
};

const getDriver = (settings: SettingsForPdfGenerationTypes, data: HydratedEntity) => {
    const isDriver = settings.isDriver;

    const vehicleLabel = <VehicleLabel vehicles={data.vehicles} expanded shouldShowFuelType={true} />;
    const driverLabel = <DriverLabel drivers={data.drivers} expanded />;
    const label = isDriver ? vehicleLabel : driverLabel;

    const truckDieselIcon = <Image src={truckDieselImage} />;
    const truckElectricIcon = <Image src={truckElectricImage} />;
    const driverIcon = <Image src={driverImage} />;

    const isElectric = data.vehicles.some(cItem => cItem.fuelType === VehicleFuelType.ELECTRIC);
    const truckImage = isElectric ? truckElectricIcon : truckDieselIcon;
    const vehicleCount = isDriver ? data.vehicles.length : data.drivers.length;
    const image = isDriver ? truckImage : driverIcon;

    const driverLabelId = isDriver
        ? isElectric
            ? 'report.electric.vehicles'
            : 'report.diesel.vehicles'
        : 'report.drivers';

    return (
        <>
            <View style={styles.description}>
                <WrappableDataboxContainer>
                    <DataboxTitleContainer>
                        <FormattedMessage id={driverLabelId} values={{ vehicleCount, image }} />
                    </DataboxTitleContainer>
                    <View style={styles.subDescription}>{label}</View>
                </WrappableDataboxContainer>
            </View>
        </>
    );
};

const getHeader = (settings: SettingsForPdfGenerationTypes, data: HydratedEntity, startDate: Date, endDate: Date) => {
    return (
        <View style={styles.top}>
            <View style={styles.left}>
                {getTitleContent(settings, data)}
                {getDateRangeContent(data, startDate, endDate)}
            </View>
            <View style={styles.right}>
                <Image src={Logo} />
            </View>
        </View>
    );
};

const getBody = (
    settings: SettingsForPdfGenerationTypes,
    data: HydratedEntity,
    startDate: Date,
    endDate: Date,
    columns: Column[],
    isElectric: boolean
) => {
    return (
        <>
            <WrappableDataboxContainer>
                <DataboxTitleContainer>
                    <FormattedMessage id={'operation.data'} />
                </DataboxTitleContainer>
                <GeneralDataPdf columns={columns} data={data} startDate={startDate} endDate={endDate} />
            </WrappableDataboxContainer>
            <WrappableDataboxContainer withBorder={true}>
                <DataboxTitleContainer>
                    <FormattedMessage id={'map'} />
                </DataboxTitleContainer>
                <OperationalRange data={data} />
            </WrappableDataboxContainer>
            <Databox title={<FormattedMessage id={'traffic'} />}>
                <TrafficData data={data} />
            </Databox>
            <Databox title={<FormattedMessage id={'heightProfile'} />}>
                <TopologyDataPdf data={data} />
            </Databox>
            <Text break />
            <ForesightedDriving data={data} settings={settings} isElectric={isElectric} />
            <CalculationParameters
                isOverspeedThresholdChanged={getSignal(data, 'overspeedThreshold', []).length > 1}
                calculationParams={settings.calculationParameters}
                isDetail={true}
                isElectric={isElectric}
            />
        </>
    );
};

export default function Content({ dateRange, data, columns, settings }: ContentProps) {
    const startDate = dateRange.start;
    const endDate =
        dateRange.end.getHours() === 0 && dateRange.end.getMinutes() === 0
            ? moment(dateRange.end)
                  .subtract(1, 'minutes')
                  .toDate()
            : dateRange.end;

    const isDriver = settings.isDriver;

    const hasElectric = data.vehicles.some(cItem => cItem.fuelType === VehicleFuelType.ELECTRIC);
    const hasDiesel = data.vehicles.some(cItem => cItem.fuelType !== VehicleFuelType.ELECTRIC);

    const buildVehicle = () => {
        const displayColumns = hasElectric ? columns.electricColumns : columns.dieselColumns;
        return (
            <>
                {getHeader(settings, data, startDate, endDate)}
                {getDriver(settings, data)}
                {getBody(settings, data, startDate, endDate, displayColumns, hasElectric)}
            </>
        );
    };

    const buildDriver = (DriverVehicleType: string) => {
        const isElectric = DriverVehicleType === VehicleFuelType.ELECTRIC;

        const displayColumns = isElectric ? columns.electricColumns : columns.dieselColumns;
        const colonizedData = clone(data);

        colonizedData.vehicles = colonizedData.vehicles.filter(vehicle => {
            return isElectric
                ? vehicle.fuelType === VehicleFuelType.ELECTRIC
                : vehicle.fuelType !== VehicleFuelType.ELECTRIC;
        });

        return (
            <>
                {getHeader(settings, colonizedData, startDate, endDate)}
                {getDriver(settings, colonizedData)}
                {getBody(settings, colonizedData, startDate, endDate, displayColumns, isElectric)}
            </>
        );
    };

    return (
        <Document>
            {!isDriver && (
                <>
                    <Page orientation="portrait" size="A4" style={styles.page}>
                        {buildVehicle()}
                        <Footer createdOn={new Date()} />
                    </Page>
                </>
            )}
            {isDriver && hasDiesel && (
                <>
                    <Page orientation="portrait" size="A4" style={styles.page}>
                        {buildDriver(VehicleFuelType.DIESEL)}
                        <Footer createdOn={new Date()} />
                    </Page>
                </>
            )}
            {isDriver && hasElectric && settings.isTruEEnabled && (
                <>
                    <Page orientation="portrait" size="A4" style={styles.page}>
                        {buildDriver(VehicleFuelType.ELECTRIC)}
                        <Footer createdOn={new Date()} />
                    </Page>
                </>
            )}
        </Document>
    );
}
