import { createId } from '@api/index';
import { getLanguageData, getLocale } from '@common/lang/selectors';
import { getFeatureToggles } from '@common/permissions/selectors';
import { Loadable } from '@data/loadable';
import { backendConfig, entitiesForGraphSelection, entitiesForRequest, getRequest } from '@data/selectors';
import { CalculationParams, enablePerform3 } from '@features/settings/reducer';
import { pdf as generatePdf } from '@react-pdf/renderer';
import { SortDirection } from '@utils/sortyByProperty';
import FileSaver from 'file-saver';
import _ from 'lodash';
import always from 'lodash/fp/always';
import { take } from 'redux-saga-test-plan/matchers';
import { all, call, delay, put, select, takeEvery } from 'redux-saga/effects';

import { AVAILABLE_CONFIGURATIONS } from '../../constants/pdf';
import {
    requestEntitiesReceivedSuccess,
    requestEntitiesStatisticsReceivedSuccess,
    requestOpConData,
    requestPerformanceData,
} from '../../data/actions';
import { configureReporting } from '../../setup/errorReporting';
import { State } from '../../setup/types';
import { DateRange, HydratedEntity, HydratedSummary, Id, OpconQueryPayload, PerformQueryPayload } from '../../types';
import { includeCruiseControlInRating } from '../settings/reducer';
import { errorNotification, infoNotification } from '../ui/notificationSaga';
import { Payload, requestPrint } from './actions';
import standardFileName from './fileName';
import Pdf, { TablePDFProps } from './tabular/Pdf';

const { captureException } = configureReporting(window, import.meta.env);

export function generatePdfOnServer(pdfServiceUrl: string, props: TablePDFProps) {
    return fetch(`${pdfServiceUrl}/table`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Accept: 'application/pdf' },
        body: JSON.stringify(props),
    })
        .then(async response => {
            return await response.blob();
        })
        .catch(e => {
            captureException(e as Error);
        });
}

export function generatePdfOnWorker(props: TablePDFProps) {
    return generatePdf(Pdf(props)).toBlob();
}

function getConfiguration(id: Id) {
    switch (id) {
        case AVAILABLE_CONFIGURATIONS.DRIVER:
            return {
                titleId: 'subModuleName.driver',
                fileNameSectionName: 'drivers',
                mappings: {
                    getKey: (element: HydratedEntity): string => _.get(element, 'drivers[0].driverId') || '',
                },
            };

        case AVAILABLE_CONFIGURATIONS.VEHICLE:
            return {
                titleId: 'vehicleAnalysis',
                fileNameSectionName: 'vehicles',
                mappings: {
                    getKey: (element: HydratedEntity): string => _.get(element, 'vehicles[0].vehicleId'),
                },
            };

        case AVAILABLE_CONFIGURATIONS.VEHICLE_AND_DRIVER:
            return {
                titleId: 'vehicleAnalysis',
                fileNameSectionName: 'vehicles-and-drivers',
                mappings: {
                    getKey: (element: HydratedEntity): string =>
                        JSON.stringify([
                            _.get(element, 'drivers[0].driverId'),
                            _.get(element, 'vehicles[0].vehicleId'),
                        ]),
                },
            };
        case AVAILABLE_CONFIGURATIONS.DRIVER_AND_VEHICLE:
            return {
                titleId: 'subModuleName.driver',
                fileNameSectionName: 'drivers-and-vehicles',
                mappings: {
                    getKey: (element: HydratedEntity): string =>
                        JSON.stringify([
                            _.get(element, 'drivers[0].driverId'),
                            _.get(element, 'vehicles[0].vehicleId'),
                        ]),
                },
            };

        default:
            throw new Error(`[pdf]: Configuration not found ${id}`);
    }
}

function* generateAndDownloadPDF({
    summary,
    summaryElectric,
    entities,
    dateRange,
    useCase,
    configuration: { titleId, fileNameSectionName },
    settings,
}: {
    summary: HydratedSummary[];
    summaryElectric: HydratedSummary[];
    entities: HydratedEntity[];
    dateRange: DateRange;
    useCase: string;
    configuration: { titleId: string; fileNameSectionName: string };
    settings: {
        totalRatingWithCC: boolean;
        columnSettings: { filteredColumnNames: string[]; columnOrder: string[] };
        sortBy: { key: string; order: SortDirection };
        calculationParameters: CalculationParams;
        isPerform3Enabled: boolean;
        isTruEEnabled: boolean;
        shouldUseV3Ratings: boolean;
    };
}) {
    const fileName = `${standardFileName({ dateRange, value: fileNameSectionName })}.pdf`;
    const languageData: Record<string, string> = yield select(getLanguageData);
    const locale: string = yield select(getLocale);
    const pdfServiceUrl: ReturnType<typeof backendConfig> = yield select(backendConfig, 'PDF_SERVICE');

    const props: TablePDFProps = {
        summary,
        summaryElectric,
        entities,
        languageData,
        locale,
        dateRange,
        useCase,
        titleId,
        settings,
        fileName,
    };

    const { printOnLambda } = yield select(getFeatureToggles);
    const blob: Blob = printOnLambda
        ? yield call(generatePdfOnServer, pdfServiceUrl, props)
        : yield call(generatePdfOnWorker, props);
    yield call(FileSaver.saveAs, blob, fileName);
}

export function* waitAndRetry(payload: Payload) {
    const aggregationQuery = payload.aggregationQuery;
    const opConQuery = payload.opConQuery;
    const summaryQuery = payload.summaryQuery as PerformQueryPayload;
    const summaryElectricQuery = payload.summaryElectricQuery as PerformQueryPayload;
    const state: State = yield select();

    const opConRequest = opConQuery ? getRequest(state, createId(opConQuery)) : Loadable.createDone([]);
    const nextSteps = [
        Loadable.cata(
            opConRequest,
            always(null),
            always(put(requestOpConData(opConQuery as OpconQueryPayload))),
            always(take(requestEntitiesReceivedSuccess)),
            always(put(requestOpConData(opConQuery as OpconQueryPayload)))
        ),
        Loadable.cata(
            getRequest(state, createId(aggregationQuery)),
            always(null),
            always(put(requestPerformanceData(aggregationQuery))),
            always(take(requestEntitiesStatisticsReceivedSuccess)),
            always(put(requestPerformanceData(aggregationQuery)))
        ),
        Loadable.cata(
            getRequest(state, createId(summaryQuery)),
            always(null),
            always(put(requestPerformanceData(summaryQuery))),
            always(take(requestEntitiesStatisticsReceivedSuccess)),
            always(put(requestPerformanceData(summaryQuery)))
        ),
        Loadable.cata(
            getRequest(state, createId(summaryElectricQuery)),
            always(null),
            always(put(requestPerformanceData(summaryElectricQuery))),
            always(take(requestEntitiesStatisticsReceivedSuccess)),
            always(put(requestPerformanceData(summaryElectricQuery)))
        ),
    ].filter(Boolean);

    yield all(nextSteps.length ? [delay(500), ...nextSteps] : nextSteps);
    yield put(requestPrint({ ...payload, retry: true }));
}

const transformOpConToDriverMap = (getKey: (e: HydratedEntity) => string) => (data: HydratedEntity[]) =>
    data.reduce(
        (map, element) =>
            // TODO:
            // score name is to generic, and our _transformer.js_ wouldn't be suited to rename the signal this time,
            // since we can't be sure that score will always mean  _operationCondition_ or maybe reflects on some other
            // score in the future.
            map.set(getKey(element), { ...element.signals, operationCondition: element.signals?.score }),
        new Map()
    );

const getOpConSignals = (
    getKey: (e: HydratedEntity) => string,
    entity: HydratedEntity,
    opCon: Map<Id, HydratedEntity>
) => {
    const key = getKey(entity);
    return opCon.get(key) || {};
};

const mergeOpConWithAggregationResponses = (getKey: (e: HydratedEntity) => string) => (
    entities: HydratedEntity[],
    opCon = new Map()
) =>
    entities.map(entity => {
        const { signals } = entity;

        const opConSignals = getOpConSignals(getKey, entity, opCon);
        return {
            ...entity,
            signals: {
                ...signals,
                ...opConSignals,
            },
        };
    });

export function* print({ payload }: { payload: Payload }) {
    const {
        aggregationQuery,
        summaryQuery,
        summaryElectricQuery,
        opConQuery,
        dateRange,
        useCase,
        id,
        retry,
        columnSettings = { filteredColumnNames: [], columnOrder: [] },
        sortBy = { key: '', order: SortDirection.ASCENDING },
        calculationParameters,
        isTruEEnabled,
        shouldUseV3Ratings,
    } = payload;
    const state: State = yield select();

    if (!retry) {
        yield call(infoNotification, 'print.started');
    }

    const configuration = getConfiguration(id);
    const keys = configuration.mappings;

    const shouldIncludeCruiseControlInRating: boolean = yield select(includeCruiseControlInRating);
    const isPerform3Enabled: boolean = yield select(enablePerform3);

    try {
        const settings = {
            totalRatingWithCC: Boolean(shouldIncludeCruiseControlInRating),
            columnSettings,
            sortBy,
            isPerform3Enabled,
            calculationParameters,
            isTruEEnabled,
            shouldUseV3Ratings,
        };

        // Select responses from state
        const requestById = (key: string) => entitiesForRequest(state, key);
        const $aggregationEntities = requestById(createId(aggregationQuery));
        const $summary = entitiesForGraphSelection(state, createId(summaryQuery));
        const $summaryElectric = entitiesForGraphSelection(state, createId(summaryElectricQuery));

        // OP_CON request is not needed, if not supplied define it as loaded to avoid any waiting
        const $opConEntities = opConQuery ? requestById(createId(opConQuery)) : Loadable.createDone([]);

        // Map OP_CON request to a map keyed by driver-ids, for now.
        const $opConMap = Loadable.map($opConEntities, transformOpConToDriverMap(keys.getKey));

        // Merge both responses together, if they have a matching partner
        const $mergedEntities = Loadable.combine(
            mergeOpConWithAggregationResponses(keys.getKey),
            $aggregationEntities,
            $opConMap
        );

        const $summaryAndEntities = Loadable.combine(
            (entities, summary, summaryElectric) => ({ entities, summary, summaryElectric }),
            $mergedEntities,
            $summary,
            $summaryElectric
        );

        // Check the current status, if everything is loaded try to generate PDF, or request data.
        yield Loadable.cata(
            $summaryAndEntities,
            ({ summary, summaryElectric, entities }) =>
                call(generateAndDownloadPDF, {
                    summary,
                    summaryElectric,
                    entities,
                    dateRange,
                    useCase,
                    configuration,
                    settings,
                }),
            () => {
                throw new Error('request failed');
            },
            () => call(waitAndRetry, payload),
            () => call(waitAndRetry, payload)
        );
    } catch (e) {
        yield call(errorNotification, 'print.errored');
        yield call(captureException, e);
    }
}

export default function* root() {
    yield takeEvery(requestPrint, print);
}
