import { getSignal } from '@api/index';
import { extractDriverName, extractVehicleName } from '@utils/stringFormatters';
import cn from 'classnames';

import { DateRange, HydratedEntity, PerformSegmentBy } from '../../../types';
import { TEMPLATE_DRIVER_REQUEST, TEMPLATE_OPCON_REQUEST, TEMPLATE_VEHICLE_OPERATION_IN_DAYS } from '../queries';
import usePdfGeneration from './usePdfGeneration';

export default function DriverPDFDownloader({
    entity,
    dateRange,
    isTruEEnabled,
    shouldUseV3Ratings,
}: {
    entity: HydratedEntity;
    dateRange: DateRange;
    isTruEEnabled: boolean;
    shouldUseV3Ratings: boolean;
}) {
    const driverIds = getSignal(entity, 'driverIds', []);
    const vehicleIds = getSignal(entity, 'vehicleIds', []);
    const isTopLevelWithMoreThan1Vehicle = vehicleIds.length > 1;
    const vehicleIdsWithLevelAwareness = isTopLevelWithMoreThan1Vehicle ? [] : vehicleIds;
    const performanceQuery = TEMPLATE_DRIVER_REQUEST({
        ...dateRange,
        driverIds,
        vehicleIds: vehicleIdsWithLevelAwareness,
        segmentBy: PerformSegmentBy.driver,
    });

    const opConQuery = TEMPLATE_OPCON_REQUEST({
        ...dateRange,
        vehicleIds: vehicleIdsWithLevelAwareness,
        driverIds,
    });

    const operationalDaysQuery = TEMPLATE_VEHICLE_OPERATION_IN_DAYS({
        ...dateRange,
        vehicleIds: vehicleIdsWithLevelAwareness,
        driverIds,
    });

    const identifier = `details-${extractDriverName(entity.drivers[0])}`;
    const fileSuffix = entity.vehicles.map(extractVehicleName).join('-');
    const { triggerDownload, hasPendingDownload } = usePdfGeneration({
        dateRange,
        // We pass along the entity to avoid having to request data twice
        entity,
        performanceQuery,
        operationalDaysQuery,
        opConQuery,
        identifier,
        fileSuffix,
        isDriver: true,
        shouldShowFuelType: entity.shouldShowFuelType,
        isTruEEnabled,
        shouldUseV3Ratings,
    });

    return (
        <button
            type="button"
            data-test="driverDetailDownload"
            className={cn('btn btn-muted btn-icon-only', { 'btn-loading': hasPendingDownload })}
            onClick={triggerDownload}
        >
            <span className={cn('rioglyph ', { 'rioglyph-download': !hasPendingDownload })} aria-hidden="true" />
        </button>
    );
}
