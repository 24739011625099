import { ApiResponse, AuthToken, TimeInMilliSeconds } from '../types';
import { obfuscateConfig } from '../utils/loggingFormatter';
import ApiError from './apiError';
import { MAX_API_RESPONSE_WAIT_TIME } from './helpers/constants';

export const PENDING = 'pending';
export const SUCCESS = 'success';

function verifyApiResponse(response: Response) {
    if (response.status < 400) {
        return response;
    }

    return response.json().then(errorDetails => {
        throw new ApiError(response.status, response.statusText, errorDetails);
    });
}

const getJSON = (response: Response) => response.json();

export const createHeaders = (authToken: string) => ({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${authToken}`,
});

export const fetchGetWithJSONResponse = async (serviceBaseUrl: string, api: string, authToken: AuthToken) => {
    const requestObject = { method: 'get', headers: createHeaders(authToken) };

    return fetch(`${serviceBaseUrl}/${api}`, requestObject)
        .then(verifyApiResponse)
        .then(getJSON);
};

// TODO we should change our api response types as they don't seem to fit right now - leaving at any for now
type PaginatedResponse<ApiResponse> = ApiResponse & { _links?: { next?: { href?: string } } };
export const fetchGetWithJSONResponseCyclicWithPagination = async <ApiResponse>(
    serviceBaseUrl: string,
    api: string,
    authToken: AuthToken
) => {
    const requestObject = { method: 'get', headers: createHeaders(authToken) };

    let responses: PaginatedResponse<ApiResponse>[] = [];
    let nextLink: string | undefined = `${serviceBaseUrl}/${api}`;

    const handleGetAllResponse = (response: PaginatedResponse<ApiResponse>) => {
        responses = [...responses, response];
        nextLink = response._links?.next?.href;
    };

    while (nextLink !== undefined) {
        await fetch(nextLink, requestObject)
            .then(verifyApiResponse)
            .then(getJSON)
            .then(handleGetAllResponse);
    }
    return responses;
};

export const fetchPostWithJSONResponse = async (
    serviceBaseUrl: string,
    api: string,
    payload: unknown,
    authToken: AuthToken
) => {
    const requestObject = {
        method: 'POST',
        headers: createHeaders(authToken),
        body: JSON.stringify(payload),
    };

    return fetch(`${serviceBaseUrl}/${api}`, requestObject)
        .then(verifyApiResponse)
        .then(getJSON);
};

export const fetchEntities = async (
    serviceBaseUrl: string,
    api: string,
    payload: Record<string, unknown>,
    authToken: AuthToken
) => {
    const headers = createHeaders(authToken);
    const baseUrl = `${serviceBaseUrl}/${api}`;

    const requestObject = {
        method: 'POST',
        headers,
        body: JSON.stringify(payload),
    };

    const postResponse = await fetch(baseUrl, requestObject);
    const location = postResponse.headers.get('Location');
    if (!location || !postResponse.ok) {
        return Promise.reject(
            `POST to ${baseUrl} failed with ${postResponse.status}. Config: ${JSON.stringify(obfuscateConfig(payload))}`
        );
    }
    const url = location.includes('http') ? location : `${serviceBaseUrl}${location}`;

    const poll = (
        fetchGet: () => Promise<ApiResponse>,
        timeout: TimeInMilliSeconds,
        interval: TimeInMilliSeconds
    ): Promise<ApiResponse> => {
        const endTime = Number(new Date()) + timeout;
        const checkCondition = (resolve: (value: ApiResponse) => void, reject: (reason?: Error) => void) => {
            fetchGet().then(response => {
                switch (response.status) {
                    case SUCCESS:
                        resolve(response);
                        break;
                    case PENDING:
                        if (Number(new Date()) < endTime) {
                            setTimeout(checkCondition, interval, resolve, reject);
                        } else {
                            reject(new Error('Response takes too long'));
                        }
                        break;
                    default:
                        reject(new Error(`messages for report ${response.id}: ${response.status_messages}`));
                }
            });
        };
        return new Promise<ApiResponse>(checkCondition);
    };
    const fetchGetResponse = () => fetch(url, { headers, method: 'GET' }).then(getJSON);
    return poll(fetchGetResponse, MAX_API_RESPONSE_WAIT_TIME, 500);
};

export const fetchDeleteWithJSONResponse = async (
    serviceBaseUrl: string,
    api: string,
    payload: string,
    authToken: AuthToken
) => {
    const requestObject = {
        method: 'DELETE',
        headers: createHeaders(authToken),
    };

    return fetch(`${serviceBaseUrl}/${api}/${payload}`, requestObject).then(res => {
        return res ? verifyApiResponse(res) : undefined;
    });
};

export const fetchPatchWithJSONResponse = async (
    serviceBaseUrl: string,
    api: string,
    id: string,
    payload: Record<string, unknown>,
    authToken: AuthToken
) => {
    const requestObject = {
        method: 'PATCH',
        headers: createHeaders(authToken),
        body: JSON.stringify(payload),
    };

    return fetch(`${serviceBaseUrl}/${api}/${id}`, requestObject).then(res => {
        return res ? verifyApiResponse(res) : undefined;
    });
};
