import { QueryTemplateVehResponse } from '@features/download/types';
import { CalculationParams } from '@features/settings/reducer';
import { createAction } from '@reduxjs/toolkit';
import { SortDirection } from '@utils/sortyByProperty';

import { DateRange, OpconQueryPayload, PerformQueryPayload } from '../../types';

export type Payload = {
    aggregationQuery: PerformQueryPayload;
    summaryQuery: PerformQueryPayload | QueryTemplateVehResponse;
    summaryElectricQuery: PerformQueryPayload | QueryTemplateVehResponse;
    opConQuery?: OpconQueryPayload;
    dateRange: DateRange;
    useCase: string;
    id: string;
    retry?: boolean;
    columnSettings: { filteredColumnNames: string[]; columnOrder: string[] };
    sortBy: { key: string; order: SortDirection } | undefined;
    calculationParameters: CalculationParams;
    isTruEEnabled: boolean;
    shouldUseV3Ratings: boolean;
};

export const requestPrint = createAction<Payload>('requestPrint');
