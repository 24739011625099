import { Driver, Vehicle } from '@api/models/types';

export interface CoDriverTableEntities {
    isLoading?: boolean | undefined;
    id: string;
    level: number;
    vehicles?: Vehicle[] | undefined;
    warnings?: unknown[] | undefined;
}

export interface CoDriverDownloadPayload {
    type: string;
    driver: Driver;
    vehicle: Vehicle;
    start: Date;
    end: Date;
    isTotalSegment: boolean;
}

export interface CoDriverRequestDownloadPayload {
    start: string;
    end: string;
    equipmentId: string;
    driverId: string;
    vin: string;
    locale: string;
}

export enum Status {
    'LOADING' = 'loading',
    'LOADED' = 'loaded',
    'FAILED' = 'failed',
}
