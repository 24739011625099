import FileSaver from 'file-saver';
import { camelCase, isArray, isPlainObject } from 'lodash';

import { CoDriverRequestDownloadPayload } from './types';

type PlainObject = { [key: string]: unknown };
type RecursiveType = PlainObject | Array<RecursiveType>;

export const renameObjectKeys = <T extends RecursiveType>(target: T): T => {
    if (isArray(target)) {
        return target.map(item => renameObjectKeys(item)) as T;
    } else if (isPlainObject(target)) {
        return Object.entries(target).reduce((res, [key, value]) => {
            res[camelCase(key)] = renameObjectKeys(value as RecursiveType);
            return res;
        }, {} as PlainObject) as T;
    }
    return target;
};

export const getCoDriverCSV = async (
    serviceBaseUrl: string,
    payload: CoDriverRequestDownloadPayload,
    authToken: string,
    timeout = 90000,
    interval = 500
) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
    };

    const requestObject = {
        method: 'POST',
        headers: headers,
    };

    const coDriverBaseUrl = `${serviceBaseUrl}/codriver_export/perf3`;
    const coDriverUrl = `${coDriverBaseUrl}?from=${payload.start}&to=${payload.end}&equipmentId=${payload.equipmentId}&driverId=${payload.driverId}&vin=${payload.vin}&locale=${payload.locale}`;

    return fetch(coDriverUrl, requestObject).then(postResponse => {
        const poll = (timeout: number, interval: number) => {
            const endTime = Number(new Date()) + timeout;
            const checkCondition = async (resolve: () => void, reject: (reason?: Error) => void) => {
                try {
                    const response = await fetch(postResponse.headers.get('Location') as string, {
                        method: 'GET',
                        headers,
                    });
                    switch (response.status) {
                        case 200:
                            FileSaver.saveAs(await response.blob(), 'utilization_data.csv');
                            resolve();
                            break;
                        case 204:
                            if (Number(new Date()) < endTime) {
                                setTimeout(checkCondition, interval, resolve, reject);
                            } else {
                                throw new Error('Response takes too long');
                            }
                            break;
                        default:
                            reject();
                    }
                } catch (error) {
                    reject(error as Error);
                }
            };
            return new Promise<void>(checkCondition);
        };
        return poll(timeout, interval).then(getResponse => {
            return getResponse !== undefined ? renameObjectKeys(getResponse) : getResponse;
        });
    });
};
