import _ from 'lodash';

import { Column } from '../../columns/createColumn';
import { getColumns } from './columns';

const operatingStateColumns = [
    'vehicle',
    'drivers',
    'operatingTime',
    'drivingTime',
    'idlingTime',
    'mileage',
    'averageWeight',
    'averageSpeed',
];

const operatingStateSummaryColumns = [
    'operatingTime',
    'drivingTime',
    'idlingTime',
    'mileage',
    'averageWeight',
    'averageSpeed',
];

const overviewColumns = [
    'vehicle',
    'drivers',
    'tripStartEnd',
    'drivingConsumption',
    'idlingConsumption',
    'averageWeight',
    'mileage',
    'fuelEfficiency',
    'system',
];

const overviewSummaryColumns = [
    'drivingConsumption',
    'idlingConsumption',
    'averageWeight',
    'mileage',
    'fuelEfficiency',
];

const overviewElectricColumns = [
    'vehicle',
    'drivers',
    'tripStartEnd',
    'electricAverageDrivingConsumption',
    'electricAverageIdlingConsumption',
    'averageWeight',
    'mileage',
    'electricEfficiency',
    'system',
];
const overviewElectricSummaryColumns = [
    'electricAverageDrivingConsumption',
    'electricAverageIdlingConsumption',
    'averageWeight',
    'mileage',
    'electricEfficiency',
];

const consumptionColumns = [
    'vehicle',
    'drivers',
    'operatingFuelConsumption',
    'fuelConsumption',
    'distanceFuelConsumption',
    'drivingConsumption',
    'idlingConsumption',
    'co2Emission',
];

const consumptionSummaryColumns = [
    'operatingFuelConsumption',
    'fuelConsumption',
    'distanceFuelConsumption',
    'drivingConsumption',
    'idlingConsumption',
    'co2Emission',
];

const ratingColumns = [
    'drivers',
    'vehicle',
    'tripStartEnd',
    'operationCondition',
    'totalRating',
    'coastingRating',
    'accelerationPedalRating',
    'brakingRating',
    'cruiseControlRating',
    'overspeedRating',
];

const ratingSummaryColumns = [
    'totalRating',
    'coastingRating',
    'accelerationPedalRating',
    'brakingRating',
    'cruiseControlRating',
    'overspeedRating',
];

const ratingElectricColumns = [
    'drivers',
    'vehicle',
    'tripStartEnd',
    'operationCondition',
    'totalRating',
    'electricAverageDrivingConsumption',
    'averageWeight',
    'mileage',
    'electricEfficiency',
];

const ratingElectricSummaryColumns = [
    'totalRating',
    'electricAverageDrivingConsumption',
    'averageWeight',
    'mileage',
    'electricEfficiency',
];

const ratingColumnsPerform3 = [
    'drivers',
    'vehicle',
    'tripStartEnd',
    'operationCondition',
    'totalRating',
    'coastingRating',
    'accelerationPedalRatingV2',
    'brakingRating',
    'cruiseControlRatingV2',
    'overspeedRating',
];

const ratingSummaryColumnsPerform3 = [
    'totalRating',
    'coastingRating',
    'accelerationPedalRatingV2',
    'brakingRating',
    'cruiseControlRatingV2',
    'overspeedRating',
];

const ratingColumnsV3 = [
    'drivers',
    'vehicle',
    'tripStartEnd',
    'operationCondition',
    'totalRating',
    'coastingRating',
    'accelerationPedalRating',
    'brakingRating',
    'cruiseControlRatingV3',
    'overspeedRating',
];

const ratingSummaryColumnsV3 = [
    'totalRating',
    'coastingRating',
    'accelerationPedalRating',
    'brakingRating',
    'cruiseControlRatingV3',
    'overspeedRating',
];

const driverOverviewColumns = [
    'drivers',
    'vehicle',
    'tripStartEnd',
    'operationCondition',
    'totalRating',
    'drivingConsumption',
    'mileage',
    'averageWeight',
    'fuelEfficiency',
];

const driverOverviewSummaryColumns = [
    'totalRating',
    'drivingConsumption',
    'mileage',
    'averageWeight',
    'fuelEfficiency',
];

export type UseCase = {
    key: string;
    columns: Column[];
    summaryColumns: Column[];
};

const useCaseConfig: UseCase[] = [
    {
        key: 'overview',
        columns: getColumns(overviewColumns),
        summaryColumns: getColumns(overviewSummaryColumns),
    },
    {
        key: 'overview-electric',
        columns: getColumns(overviewElectricColumns),
        summaryColumns: getColumns(overviewElectricSummaryColumns),
    },
    {
        key: 'driver-overview',
        columns: getColumns(driverOverviewColumns),
        summaryColumns: getColumns(driverOverviewSummaryColumns),
    },
    {
        key: 'driver-overview-electric',
        columns: getColumns(driverOverviewColumns),
        summaryColumns: getColumns(driverOverviewSummaryColumns),
    },
    {
        key: 'ratings',
        columns: getColumns(ratingColumns),
        summaryColumns: getColumns(ratingSummaryColumns),
    },
    {
        key: 'ratings-electric',
        columns: getColumns(ratingElectricColumns),
        summaryColumns: getColumns(ratingElectricSummaryColumns),
    },
    {
        key: 'ratings-perform3',
        columns: getColumns(ratingColumnsPerform3),
        summaryColumns: getColumns(ratingSummaryColumnsPerform3),
    },
    {
        key: 'ratings-perform3-electric',
        columns: getColumns(ratingColumnsPerform3),
        summaryColumns: getColumns(ratingSummaryColumnsPerform3),
    },
    {
        key: 'ratings-v3',
        columns: getColumns(ratingColumnsV3),
        summaryColumns: getColumns(ratingSummaryColumnsV3),
    },
    {
        key: 'ratings-v3-electric',
        columns: getColumns(ratingColumnsV3),
        summaryColumns: getColumns(ratingSummaryColumnsV3),
    },
    {
        key: 'consumption',
        columns: getColumns(consumptionColumns),
        summaryColumns: getColumns(consumptionSummaryColumns),
    },
    {
        key: 'consumption-electric',
        columns: getColumns(consumptionColumns),
        summaryColumns: getColumns(consumptionSummaryColumns),
    },
    {
        key: 'operating-condition',
        columns: getColumns(operatingStateColumns),
        summaryColumns: getColumns(operatingStateSummaryColumns),
    },
    {
        key: 'operating-condition-electric',
        columns: getColumns(operatingStateColumns),
        summaryColumns: getColumns(operatingStateSummaryColumns),
    },
];

export const getUseCase = (isPerform3Enabled: boolean, shouldUseV3Ratings: boolean) => (
    useCaseKey: string
): UseCase | undefined => {
    const isRatingsUseCase = useCaseKey === 'ratings';

    let key =
        shouldUseV3Ratings && isRatingsUseCase
            ? 'ratings-v3'
            : isPerform3Enabled && isRatingsUseCase
            ? 'ratings-perform3'
            : useCaseKey;

    return _.find(useCaseConfig, ['key', key]);
};
