import { getColumns } from '@utils/columns';
import _ from 'lodash';

import { Column } from '../../columns/createColumn';
import {
    accelerationPedalRating,
    accelerationPedalRatingV2,
    cruiseControlRatingV2,
    cruiseControlRatingV3,
    totalRatingV2,
    totalRatingV3,
} from '../../columns/percentage';
import { CalculationParams, defaultCalculationParams } from './../../features/settings/reducer';
import { replacements, tableColumns } from './columns';
import { UseCaseKey } from './driverAnalysisReducer';

const overviewColumns = [
    'drivers',
    'vehicles',
    'tripStartEnd',
    'operationCondition',
    'totalRating',
    'drivingConsumption',
    'mileage',
    'averageWeight',
    'fuelEfficiency',
];
const overviewSummaryColumns = ['totalRating', 'drivingConsumption', 'mileage', 'averageWeight', 'fuelEfficiency'];

const harshRatings = ['harshAccelerationRating', 'harshBrakingRating'];

const excessiveIdlingRating = ['excessiveIdlingRating'];

const ratingColumns = [
    'drivers',
    'vehicles',
    'tripStartEnd',
    'operationCondition',
    'totalRating',
    'coastingRating',
    'accelerationPedalRating',
    'brakingRating',
    'cruiseControlRating',
    'overspeedRating',
    ...harshRatings,
    ...excessiveIdlingRating,
];
const ratingSummaryColumns = [
    'totalRating',
    'coastingRating',
    'accelerationPedalRating',
    'brakingRating',
    'cruiseControlRating',
    'overspeedRating',
    ...harshRatings,
    ...excessiveIdlingRating,
];

export type UseCaseConfig = {
    key: string;
    columns: Column[];
    summaryColumns: Column[];
};

export const useCaseConfig: UseCaseConfig[] = [
    {
        key: 'overview',
        columns: getColumns(overviewColumns, tableColumns),
        summaryColumns: getColumns(overviewSummaryColumns),
    },
    {
        key: 'ratings',
        columns: getColumns(ratingColumns, tableColumns),
        summaryColumns: getColumns(ratingSummaryColumns),
    },
];

type Settings = {
    calculationParams: CalculationParams;
    shouldUseV2Ratings: boolean;
    includeCruiseControlInRating: boolean;
    shouldShowHarshKPIs: boolean;
    shouldShowExcessiveKPIs: boolean;
    shouldUseV3Ratings: boolean;
};
export const getUseCaseConfigForKey = (
    useCaseKey: UseCaseKey,
    {
        includeCruiseControlInRating,
        shouldUseV2Ratings,
        calculationParams,
        shouldShowHarshKPIs,
        shouldShowExcessiveKPIs,
        shouldUseV3Ratings,
    }: Settings = {
        includeCruiseControlInRating: true,
        calculationParams: defaultCalculationParams,
        shouldUseV2Ratings: true,
        shouldShowHarshKPIs: false,
        shouldShowExcessiveKPIs: false,
        shouldUseV3Ratings: false,
    }
): UseCaseConfig | undefined => {
    const config = _.find(useCaseConfig, ['key', useCaseKey]);
    const shouldExcludeColumn = (column: Column, shouldShowHarshKPIs: boolean) => {
        const hiddenColumns = Object.keys(calculationParams).reduce(
            (acc: string[], columnName) =>
                calculationParams[columnName as keyof CalculationParams].enabled
                    ? acc
                    : [...acc, columnName.toLowerCase()],
            []
        );

        if (!shouldShowHarshKPIs) {
            harshRatings.forEach(rating => hiddenColumns.push(rating.toLowerCase()));
        }

        if (!shouldShowExcessiveKPIs) {
            excessiveIdlingRating.forEach(rating => hiddenColumns.push(rating.toLowerCase()));
        }

        return hiddenColumns.includes(column.key.toLowerCase());
    };

    if (config) {
        return {
            ...config,
            summaryColumns: config.summaryColumns.reduce((acc: Column[], column: Column) => {
                if (shouldExcludeColumn(column, shouldShowHarshKPIs)) {
                    return acc;
                }

                if (shouldUseV3Ratings) {
                    if (column.key === 'accelerationPedalRating') {
                        acc.push(accelerationPedalRating);
                        return acc;
                    }

                    if (column.key === 'cruiseControlRating') {
                        acc.push(cruiseControlRatingV3);
                        return acc;
                    }

                    if (column.key === 'totalRating') {
                        acc.push(totalRatingV3);
                        return acc;
                    }
                } else if (shouldUseV2Ratings) {
                    if (column.key === 'accelerationPedalRating') {
                        acc.push(accelerationPedalRatingV2);
                        return acc;
                    }

                    if (column.key === 'cruiseControlRating') {
                        acc.push(cruiseControlRatingV2);
                        return acc;
                    }

                    if (column.key === 'totalRating') {
                        acc.push(totalRatingV2);
                        return acc;
                    }
                }

                acc.push(column);

                return acc;
            }, []),
            columns: config.columns.reduce((acc: Column[], column: Column) => {
                if (shouldExcludeColumn(column, shouldShowHarshKPIs)) {
                    return acc;
                }

                if (shouldUseV3Ratings) {
                    if (column.key === 'accelerationPedalRating') {
                        acc.push({ ...accelerationPedalRating, ...replacements.accelerationPedalRating });
                        return acc;
                    }

                    if (column.key === 'cruiseControlRating') {
                        acc.push({ ...cruiseControlRatingV3, ...replacements.cruiseControlRatingV3 });
                        return acc;
                    }

                    if (column.key === 'totalRating') {
                        acc.push({ ...totalRatingV3, ...replacements.totalRatingV3 });
                        return acc;
                    }
                } else if (shouldUseV2Ratings) {
                    if (column.key === 'accelerationPedalRating') {
                        acc.push({ ...accelerationPedalRatingV2, ...replacements.accelerationPedalRatingV2 });
                        return acc;
                    }

                    if (column.key === 'cruiseControlRating') {
                        acc.push({ ...cruiseControlRatingV2, ...replacements.cruiseControlRatingV2 });

                        return acc;
                    }

                    if (column.key === 'totalRating') {
                        acc.push({ ...totalRatingV2, ...replacements.totalRatingV2 });

                        return acc;
                    }
                }

                acc.push(column);

                return acc;
            }, []),
        };
    }

    return;
};
