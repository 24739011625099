import { getSignal } from '@api/index';
import { DateRangePropType, IdPropType } from '@utils/commonPropTypes';
import { extractDriverName, extractVehicleName } from '@utils/stringFormatters';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DateRange, Driver, HydratedEntity, PerformSegmentBy } from '../../../types';
import { TEMPLATE_FORESIGHTED_REQUEST, TEMPLATE_OPCON_REQUEST, TEMPLATE_VEHICLE_OPERATION_IN_DAYS } from '../queries';
import usePdfGeneration from './usePdfGeneration';

export default function VehiclePDFDownloader({
    entity,
    dateRange,
    level,
    performVehicleIds = [],
    isTruEEnabled,
    shouldUseV3Ratings,
}: {
    entity: HydratedEntity;
    dateRange: DateRange;
    level: number | undefined;
    performVehicleIds: string[];
    isTruEEnabled?: boolean;
    shouldUseV3Ratings?: boolean;
}) {
    const vehicle = entity.vehicles[0];
    const isPerformVehicle = performVehicleIds.includes(_.get(vehicle, 'vehicleId'));
    const vehicleIds = getSignal(entity, 'vehicleIds', []);
    const driverIds = level === 1 ? [] : getSignal(entity, 'driverIds', []);

    const performanceQuery = TEMPLATE_FORESIGHTED_REQUEST({
        ...dateRange,
        driverIds,
        vehicleIds,
        segmentBy: PerformSegmentBy.vehicle,
    });

    let driverIdsForOpCon = level !== 1 && driverIds.length === 0 ? [null] : driverIds;

    const opConQuery = TEMPLATE_OPCON_REQUEST({
        ...dateRange,
        vehicleIds,
        driverIds: driverIdsForOpCon,
    });

    const operationalDaysQuery = TEMPLATE_VEHICLE_OPERATION_IN_DAYS({
        ...dateRange,
        vehicleIds,
        driverIds: driverIdsForOpCon,
    });

    const identifier = `details-${extractVehicleName(vehicle)}`;
    const fileSuffix = entity.drivers.map(extractDriverName as (d: Driver) => string).join('-');

    const { triggerDownload, hasPendingDownload } = usePdfGeneration({
        dateRange,
        // We pass along also the entity, this can save us from making 1 additional request.
        // This can allow us to reuse previously loaded information. Instead of hitting the API.
        entity,
        performanceQuery,
        operationalDaysQuery,
        opConQuery,
        identifier,
        fileSuffix,
        isTruEEnabled,
        shouldUseV3Ratings,
    });

    return (
        <button
            disabled={!isPerformVehicle}
            data-test="vehicleDetailDownload"
            type="button"
            className={cn('btn btn-icon-only btn-muted', { 'btn-loading': hasPendingDownload })}
            onClick={triggerDownload}
        >
            <span className={cn('rioglyph', { 'rioglyph-download': !hasPendingDownload })} aria-hidden="true" />
        </button>
    );
}

VehiclePDFDownloader.propTypes = {
    entity: PropTypes.object,
    dateRange: DateRangePropType,
    level: PropTypes.number,
    performVehicleIds: PropTypes.arrayOf(IdPropType),
};
