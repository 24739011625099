import { DEFAULT_LOCALE } from '@common/lang/lang';
import { CalculationParams } from '@features/settings/reducer';
import { Text } from '@react-pdf/renderer';
import { IntlProvider } from 'react-intl';

import { DateRange, HydratedEntity } from '../../../types';
import { getColumns } from '../columns';
import FontProvider from '../FontProvider';
import Content from './Content';

export type DetailPDFProps = {
    data: HydratedEntity;
    languageData: Record<string, string>;
    dateRange: DateRange;
    locale: string;
    settings: {
        totalRatingWithCC: boolean;
        isDriver: boolean;
        shouldShowOvertakeInCruise?: boolean;
        calculationParameters: CalculationParams;
        isPerform3Enabled: boolean;
        shouldShowFuelType: boolean;
        isTruEEnabled?: boolean;
        shouldUseV3Ratings?: boolean;
    };
};

const dieselColumns = [
    'operatingFuelConsumption',
    'fuelConsumption',
    'distanceFuelConsumption',
    'drivingConsumption',
    'idlingConsumption',
    'fuelEfficiency',
    'co2Emission',
    'operatingTime',
    'drivingTime',
    'idlingTime',
    'mileage',
    'averageWeight',
    'averageSpeed',
];

const electricColumns = [
    'electricAverageOperatingConsumption',
    'electricOperatingConsumption',
    'electricAverageOperatingConsumptionRange',
    'electricAverageDrivingConsumption',
    'electricAverageIdlingConsumption',
    'electricRecuperation',
    'electricEfficiency',
    'co2Emission',
    'operatingTime',
    'drivingTime',
    'idlingTime',
    'mileage',
    'averageWeight',
    'averageSpeed',
    'operatingDays',
];

function Pdf({ data, languageData, locale, dateRange, settings }: DetailPDFProps) {
    const columns = {
        electricColumns: settings.isTruEEnabled ? getColumns(electricColumns) : [],
        dieselColumns: getColumns(dieselColumns),
    };

    return (
        <FontProvider locale={locale}>
            <IntlProvider
                key={locale}
                locale={locale}
                messages={languageData}
                defaultLocale={DEFAULT_LOCALE}
                textComponent={Text}
            >
                <Content columns={columns} dateRange={dateRange} data={data} settings={settings} />
            </IntlProvider>
        </FontProvider>
    );
}

export default Pdf;
