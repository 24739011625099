import { isLATAMTenant } from '@common/login/selectors';
import { getFeatureToggles, getPerformReducedBus, getPerformVehicles } from '@common/permissions/selectors';
import Box from '@components/Box';
import Column from '@components/Column';
import ConditionalTooltip from '@components/ConditionalTooltip';
import OperatingPeriod from '@components/OperatingPeriod';
import { getSelectedVehicleFuelTypes } from '@data/selectors';
import OperationalDays from '@features/operationalDays';
import PDFDownloader from '@features/pdf/detail/VehiclePDFDownloader';
import { hideSidebar, showSidebar, sidebarData } from '@features/ui/reducer';
import { getActiveDateRange } from '@features/ui/selectors';
import EmptyState from '@rio-cloud/rio-uikit/EmptyState';
import Sidebar from '@rio-cloud/rio-uikit/Sidebar';
import { combustionColumnNames, commonColumnNames, electricColumnNames, getColumns } from '@utils/columns';
import { extractVehicleName } from '@utils/stringFormatters';
import { trackingAttributes } from '@utils/tracking';
import _ from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Column as ColumnType } from 'src/columns/createColumn';
import { State } from 'src/setup/types';
import { DateRange, HydratedEntity, HydratedEntityWithChildren, Id, Vehicle } from 'src/types';

import { getSignal, VehicleFuelType } from '../../api';
import { VEHICLE_ROUTE } from '../../constants/routes';
import convertToRows from '../../utils/convertToRows';
import { openRow } from './actions';
import { getTopColumns } from './columns';
import { entitySidePanelSelection } from './selectors';
import Tabs from './SidepanelTabs';
import { decorateWithAdditionalInformation } from './utils';

const isValidDate = (date: Date | undefined) => date && moment(date).isValid();
const convertToRow = convertToRows(3);

export const FormattedOperationDays = ({ entity }: { entity: HydratedEntity }) => (
    <div className=" margin-bottom-5">
        <label className="margin-bottom-0 text-size-12">
            <FormattedMessage id="operationDays" />
        </label>
        <div className="text-size-18 ellipsis-1">
            <OperationalDays
                operatingDays={getSignal(entity, 'operatingDays', undefined)}
                totalNumberOfDays={getSignal(entity, 'totalNumberOfDays', undefined)}
            />
        </div>
    </div>
);

export type Props = {
    entity: Omit<HydratedEntityWithChildren, 'level'>;
    level: number | undefined;
    dateRange: DateRange;
    onClose: () => void;
    hasPerformVehicle: boolean;
    performVehicleIds: Id[];
    performReducedBusIds: Id[];
    onDriverClick: (data: { id: Id; level: number; dateRange: DateRange }) => void;
    openParent: (id: Id) => void;
    isLATAMTenant?: boolean;
    shouldShowOvertakeInCruise?: boolean;
    isTruEEnabled?: boolean;
    shouldUseV3Ratings?: boolean;
    selectedVehicleFuelTypes: Set<VehicleFuelType>;
};
export const Sidepanel = ({
    entity,
    level,
    dateRange,
    onClose,
    hasPerformVehicle,
    performVehicleIds,
    performReducedBusIds,
    onDriverClick,
    openParent,
    isLATAMTenant = false,
    shouldShowOvertakeInCruise = false,
    isTruEEnabled = false,
    shouldUseV3Ratings = false,
    selectedVehicleFuelTypes,
}: Props) => {
    const onDriverNameClick = useCallback(
        driver => {
            // TODO: figure out why id needs to be an array!?
            onDriverClick({ id: driver.id, level: 2, dateRange });
            openParent(entity.id);
        },
        [onDriverClick, openParent, entity, dateRange]
    );

    if (
        !isValidDate(getSignal<Date | undefined>(entity, 'start', undefined)) ||
        !isValidDate(getSignal<Date | undefined>(entity, 'end', undefined))
    ) {
        return (
            <Sidebar fly onClose={onClose} position={'right'} width={1000}>
                <div className="padding-20 padding-top-15">
                    <EmptyState
                        headline={<FormattedMessage id="noData" />}
                        message={<FormattedMessage id="noData.explanation" />}
                    />
                </div>
            </Sidebar>
        );
    }

    const vehicle = extractVehicleName(_.get(entity, 'vehicles[0]', {} as Vehicle));
    const isElectricVehicle = _.get(entity, 'vehicles[0]')?.fuelType === VehicleFuelType.ELECTRIC;
    const shouldShowFuelType = isTruEEnabled && selectedVehicleFuelTypes.has(VehicleFuelType.ELECTRIC);
    const entityWithAdditionalInformation = decorateWithAdditionalInformation({
        entity,
        performVehicleIds,
        performReducedBus: performReducedBusIds,
        shouldShowFuelType,
    });

    const isDriverNameClickable = hasPerformVehicle && level === 1 && entity.children && entity.children.length > 1;
    const topColumns = getTopColumns(isDriverNameClickable, onDriverNameClick, hasPerformVehicle);

    const topRows = convertToRow(
        topColumns
            .filter((column): column is ColumnType => !!column)
            .map(column => <Column key={column.key} column={column} entity={entityWithAdditionalInformation} />)
    );

    const startDate = dateRange.start;
    const endDate =
        dateRange.end.getHours() === 0 && dateRange.end.getMinutes() === 0
            ? moment(dateRange.end)
                  .subtract(1, 'minutes')
                  .toDate()
            : dateRange.end;

    const vehicleSpecialColumns = getColumns(
        isElectricVehicle && isTruEEnabled ? electricColumnNames : combustionColumnNames
    );
    let vehicleColumns = vehicleSpecialColumns.concat(getColumns(commonColumnNames));

    const updateLabelId = (arr: ColumnType[], key: string, newLabelId: string) => {
        const obj = arr.find(item => item.key === key);
        if (obj) obj.labelId = newLabelId;
        return arr;
    };

    const idlingTimeLabelId = isElectricVehicle ? 'idlingTimeBev' : 'idlingTime';

    vehicleColumns = updateLabelId(vehicleColumns, 'idlingTime', idlingTimeLabelId);

    let displayedVehicleColumns = vehicleColumns
        .map((column: ColumnType) => <Column key={column.key} column={column} entity={entity as HydratedEntity} />)
        .concat([<FormattedOperationDays key="operationDays" entity={entity} />]);
    const vehicleRows = convertToRow(displayedVehicleColumns);
    return (
        <Sidebar
            fly
            title={
                <div className="padding-left-10 justify-content-between flex align-items-center">
                    <FormattedMessage
                        id="vehicleAnalysis.sidebar.title.oneVehicle"
                        values={{
                            vehicle,
                            start: <FormattedDate value={startDate} />,
                            end: <FormattedDate value={endDate} />,
                        }}
                    />
                </div>
            }
            onClose={onClose}
            position={'right'}
            width={1000}
            bodyClassName={'overflow-y-scroll'}
            headerButtons={
                <ConditionalTooltip
                    enabled={!hasPerformVehicle}
                    // eslint-disable-next-line react/display-name
                    getTooltipChildren={() => <FormattedMessage id={'featureNotIncludedInEssentials'} />}
                >
                    <div>
                        <PDFDownloader
                            entity={entity}
                            dateRange={dateRange}
                            level={level}
                            performVehicleIds={performVehicleIds}
                            isTruEEnabled={isTruEEnabled}
                            shouldUseV3Ratings={shouldUseV3Ratings}
                        />
                    </div>
                </ConditionalTooltip>
            }
        >
            <OperatingPeriod
                start={getSignal<Date | undefined>(entity, 'start', undefined) as Date}
                end={getSignal<Date | undefined>(entity, 'end', undefined) as Date}
            />
            <div
                className="padding-20 padding-top-15"
                data-test="VehicleSidePanel"
                {...trackingAttributes(
                    'visibility',
                    'perform, detailsPanel',
                    'openVehicleDetailsPanel',
                    'openDetailsPanel, fleetAnalysis'
                )}
            >
                <Box rows={topRows} />
                <Box rows={vehicleRows} hasBorder={false} />
                {!isLATAMTenant && (
                    <Tabs
                        level={level}
                        dateRange={dateRange}
                        entity={entity}
                        hasPerformVehicle={hasPerformVehicle}
                        performReducedBusIds={performReducedBusIds}
                        shouldShowOvertakeInCruise={shouldShowOvertakeInCruise}
                        isElectricVehicle={isElectricVehicle}
                    />
                )}
            </div>
        </Sidebar>
    );
};

const mapStateToProps = (state: State) => {
    const sideBarData = sidebarData(state, VEHICLE_ROUTE);
    const entityWithChildren = entitySidePanelSelection(state);

    const performVehicles = getPerformVehicles(state);
    const vehicleIds = entityWithChildren.vehicles.map(vehicle => vehicle.vehicleId);
    const hasPerformVehicle = _.intersection(vehicleIds, performVehicles).length !== 0;
    const performReducedBus = getPerformReducedBus(state);

    return {
        entity: entityWithChildren,
        level: _.get(sideBarData, 'level'),
        dateRange: getActiveDateRange(state),
        hasPerformVehicle,
        performVehicleIds: performVehicles,
        performReducedBusIds: performReducedBus,
        isLATAMTenant: isLATAMTenant(state),
        shouldShowOvertakeInCruise: getFeatureToggles(state).showOvertakeInCruise as boolean,
        isTruEEnabled: getFeatureToggles(state).truE_EEF as boolean,
        shouldUseV3Ratings: getFeatureToggles(state).newOvertakeModel as boolean,
        selectedVehicleFuelTypes: getSelectedVehicleFuelTypes(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    onClose: () => dispatch(hideSidebar()),
    onDriverClick: (data: { id: Id; level: number; dateRange: DateRange }) =>
        dispatch(showSidebar({ data, type: VEHICLE_ROUTE })),
    openParent: (id: Id) => dispatch(openRow({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidepanel);
