import colors from '@rio-cloud/rio-uikit/Colors';
import { axisLeft, axisRight } from 'd3-axis';
import { ScaleLinear, scaleTime } from 'd3-scale';
import { select, Selection } from 'd3-selection';
import { first, isNumber, last, noop } from 'lodash';
import { useContext, useEffect, useRef } from 'react';

import { GraphContext } from './GraphDataProvider';

export const cleanupYScaleTicks = ({
    height,
    yScale,
    ticks,
    shouldHideLowerBoundTick = true,
    spaceUntilMaxTickAndTop = 4,
}: {
    height: number;
    yScale: ScaleLinear<number, unknown>;
    ticks: number[];
    spaceUntilMaxTickAndTop?: number;
    shouldHideLowerBoundTick?: boolean;
}) => {
    const [maxValue] = yScale.domain();
    const largestTick = last(ticks);
    const smallestTick = first(ticks);
    const step = ticks[1] - ticks[0];
    const newMaxStep = (largestTick as number) + step;
    const spaceToLowerBound = height - (yScale(smallestTick!) as number);

    // if there is less than 20 pixel of space to lower bound we remove the tick
    if (shouldHideLowerBoundTick && spaceToLowerBound < 20) {
        ticks.shift();
    }

    // if have more than 4 pixel space add a new step
    if (largestTick! < maxValue && (yScale(newMaxStep) as number) > spaceUntilMaxTickAndTop) {
        ticks.push(newMaxStep);
    }

    return ticks;
};

const milliSecondsToSeconds = (milliSeconds: Date | number) => (milliSeconds as number) / 1000;
const secondsToMilliSeconds = (seconds: Date | number) => (seconds as number) * 1000;

export const generateTicksFromSeconds = (y: ScaleLinear<number, unknown>, height: number) => {
    const [maxValue, minValue] = y.domain();
    const time = scaleTime().domain([secondsToMilliSeconds(minValue), secondsToMilliSeconds(maxValue)]);

    const yTicks = time.ticks(5).map(milliSecondsToSeconds);

    return cleanupYScaleTicks({ height, yScale: y, ticks: yTicks });
};

export const generateTicks = (y: ScaleLinear<number, unknown>, height: number) => {
    const yTicks = y.ticks(5).reverse();
    return cleanupYScaleTicks({ height, yScale: y, ticks: yTicks });
};

export function YAxisTicks({
    formatter,
    position = 'leftY',
    tickGenerator = generateTicks,
    customTicksYValues,
    units,
    unitsColor,
    saveTicks = noop,
}: {
    formatter: (value: number) => string;
    position?: 'leftY' | 'rightY';
    tickGenerator?: (y: ScaleLinear<number, number>, height: number) => number[];
    customTicksYValues?: number[];
    units?: string;
    unitsColor?: string;
    saveTicks?: (ticks: number[]) => void;
}): JSX.Element {
    const {
        leftYScale,
        rightYScale,
        dimensions: { margin, height, width },
    } = useContext(GraphContext);
    const axisRef = useRef(null);
    const ticksYValues = useRef<number[]>([]);

    useEffect(() => {
        if (axisRef && axisRef.current) {
            const el: Selection<SVGGElement, unknown, HTMLElement, unknown> = select(axisRef.current);

            let yAxis,
                ticksValues: number[] = [];

            if (!!customTicksYValues && customTicksYValues.length > 1) {
                const scale = position === 'leftY' ? leftYScale : rightYScale;
                ticksValues = customTicksYValues
                    .map(customTickYValue => parseFloat(scale!.invert(customTickYValue).toFixed(2)))
                    .filter(isNumber);
            } else {
                if (position === 'leftY') {
                    ticksValues = tickGenerator(leftYScale, height);
                    ticksYValues.current = ticksValues.map(tick => leftYScale(tick));
                } else {
                    ticksValues = tickGenerator(rightYScale as ScaleLinear<number, number>, height);
                    ticksYValues.current = ticksValues.map(tick => rightYScale && rightYScale(tick));
                }
            }

            if (position === 'leftY') {
                yAxis = axisRight(leftYScale as ScaleLinear<number, number>)
                    .tickValues(ticksValues)
                    .tickFormat(value => formatter(Math.abs(value as number)));
            } else {
                yAxis = axisLeft(rightYScale as ScaleLinear<number, number>)
                    .tickValues(ticksValues)
                    .tickFormat(value => formatter(Math.abs(value as number)));
            }

            el.call(yAxis as never)
                .selectAll('.units')
                .remove();

            if (units && unitsColor) {
                let translateUnitsXValue;
                if (position === 'leftY') {
                    translateUnitsXValue = margin / 2;
                } else {
                    translateUnitsXValue = 20;
                }

                el.call(yAxis as any)
                    .append('text')
                    .attr('class', 'units')
                    .attr('fill', unitsColor)
                    .attr('transform', `translate(${translateUnitsXValue}, 10)`)
                    .style('font-size', '12px')
                    .style('font-weight', 'bold')
                    .text(units);
            }

            el.select('.domain').remove();
            el.selectAll('.tick line').remove();
            el.selectAll('.tick text')
                .attr('x', margin / 2)
                .attr('dy', 12);
        }
    }, [
        axisRef,
        formatter,
        height,
        margin,
        position,
        tickGenerator,
        units,
        width,
        unitsColor,
        leftYScale,
        rightYScale,
        customTicksYValues,
        ticksYValues,
    ]);

    useEffect(() => {
        saveTicks(ticksYValues.current);
    });

    let translateXValue;
    if (position.includes('left')) {
        translateXValue = -10;
    } else {
        translateXValue = width + 10;
    }

    return (
        <g transform={`translate(${translateXValue} 0)`}>
            <g ref={axisRef} className="axis yAxisTicks" textAnchor="start" data-test={`${position}Axis`} />
        </g>
    );
}

export function YAxisLines({ tickGenerator = generateTicks, yScale = 'leftYScale' }): JSX.Element {
    const {
        leftYScale,
        rightYScale,
        dimensions: { widthWithoutMargin, height },
    } = useContext(GraphContext);
    const axisRef = useRef(null);

    useEffect(() => {
        if (axisRef && axisRef.current) {
            const el = select(axisRef.current);
            let yAxis;
            if (yScale === 'leftYScale') {
                yAxis = axisRight(leftYScale).tickValues(tickGenerator(leftYScale, height));
            } else {
                yAxis = rightYScale && axisRight(rightYScale).tickValues(tickGenerator(rightYScale, height));
            }

            el.call(yAxis as any);
            el.select('.domain').remove();
            el.selectAll('.tick text').remove();
            el.selectAll('.tick line')
                .attr('x0', 0)
                .attr('stroke', colors['gray-lighter'])
                .attr('x1', widthWithoutMargin + 10);
        }
    }, [axisRef, height, tickGenerator, widthWithoutMargin, rightYScale, yScale, leftYScale]);

    return (
        <g transform={`translate(-10 0)`}>
            <g ref={axisRef} className="axis yAxisLines" textAnchor="start" />
        </g>
    );
}
